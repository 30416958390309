import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { renderElements } from "../components/elements"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
        id
        slug
        title
        renderContent
        googleDescription
        googleTitle
        smTitle
        smDescription
    }
  }
`
const SecondPage = props => {

    return (
        <Layout
            contentObjects={JSON.parse(props.data.wpPage.renderContent)}
            slug={props.data.wpPage.slug}
        >
            <Helmet>
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#7abc7c" />
            <meta name="msapplication-TileColor" content="#28b4e4" />
            <meta name="theme-color" content="#ffffff" />
            </Helmet>
            <SEO
                title={props.data.wpPage.title}
                seoMetaTags={{
                    googleDescription: props.data.wpPage.googleDescription,
                    googleTitle: props.data.wpPage.googleTitle,
                    smTitle: props.data.wpPage.smTitle,
                    smDescription: props.data.wpPage.smDescription
                }} />

            {renderElements(props.data.wpPage.renderContent)}
        </Layout>
    )
}

export default SecondPage